<template>
  <svg class="w-[28px] h-auto" width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_77_2)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M280.488 0C267.017 0 256.098 10.9199 256.098 24.3902V219.512C256.098 232.983 267.017 243.902 280.488 243.902H475.61C489.08 243.902 500 232.983 500 219.512V24.3902C500 10.9199 489.08 0 475.61 0H280.488ZM378.049 225.61C435.298 225.61 481.707 179.2 481.707 121.951C481.707 64.7022 435.298 18.2927 378.049 18.2927C320.8 18.2927 274.39 64.7022 274.39 121.951C274.39 179.2 320.8 225.61 378.049 225.61Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3902 0C10.9199 0 0 10.9199 0 24.3902V219.512C0 232.983 10.9199 243.902 24.3902 243.902H219.512C232.983 243.902 243.902 232.983 243.902 219.512V24.3902C243.902 10.9199 232.983 0 219.512 0H24.3902ZM226.295 90.358C226.336 83.8894 223.807 77.6692 219.262 73.0658L173.168 26.3739C168.623 21.7705 162.436 19.1609 155.968 19.1193L90.3581 18.6967C83.8895 18.655 77.6693 21.1847 73.0659 25.7292L26.374 71.8235C21.7706 76.368 19.161 82.5551 19.1194 89.0236L18.6968 154.633C18.6551 161.102 21.1848 167.322 25.7293 171.925L71.8236 218.617C76.3681 223.221 82.5552 225.83 89.0237 225.872L154.633 226.295C161.102 226.336 167.322 223.807 171.926 219.262L218.617 173.168C223.221 168.623 225.83 162.436 225.872 155.968L226.295 90.358Z" fill="white"/>
  <rect y="256.098" width="243.902" height="243.902" rx="2" fill="white"/>
  <rect x="256.098" y="256.098" width="243.902" height="243.902" rx="2" fill="white"/>
  <path d="M60.9756 458.537V304.878H113.439C158.659 304.878 189.61 335.61 189.61 381.707C189.61 424.512 160.854 458.537 113.439 458.537H60.9756ZM89.2927 431.756H111.463C141.976 431.756 158.878 413.756 158.878 381.707C158.878 349.878 141.976 331.878 111.463 331.878H89.2927V431.756Z" fill="black"/>
  <path d="M404.878 398.829L443.073 456.341V458.537H408.39L373.049 402.341H347.146V458.537H317.073V304.878H384.463C416.951 304.878 435.829 326.829 435.829 352.951C435.829 375.341 423.756 392.024 404.878 398.829ZM347.146 331.878V375.561H382.927C398.293 375.561 404.878 365.463 404.878 353.171C404.878 341.537 398.073 331.878 382.927 331.878H347.146Z" fill="black"/>
  </g>
  <defs>
  <clipPath id="clip0_77_2">
  <rect width="500" height="500" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>